import styles from './index.module.less';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useWindowSize } from 'react-use';

const CookieConsentContent = () => {
  const { t } = useTranslation();
  const { isShowArticleBottomAlert } = useConfigProvider();
  const { width } = useWindowSize();

  const handleDecline = () => {
    // 自定义逻辑，比如删除 cookies
    Cookies.remove('myPlanbowlCookieConsent'); // 删除 cookie
  };

  return (
    <>
      {!isShowArticleBottomAlert && width > 768 && (
        <CookieConsent
          location="bottom"
          buttonText={t('Accept')}
          cookieName="myPlanbowlCookieConsent"
          expires={365}
          enableDeclineButton
          declineButtonText={t('Not Now')}
          onDecline={handleDecline}
          containerClasses={styles.cookieConsentContainer}
          contentClasses={styles.cookieConsentMessage}
          buttonWrapperClasses={styles.cookieConsentButtons}
          style={{ background: '#EAE8FF', zIndex: '9999' }}
          buttonClasses={styles.btnStyle}
          declineButtonClasses={styles.declineBtnStyle}
          buttonStyle={{
            backgroundColor: 'var(--app-primary-color)',
            color: `var(--other-text-color)`,
            fontSize: '14px',
            borderRadius: '4px',
            padding: '8px',
            margin: '15px 15px 0 15px',
          }}
          declineButtonStyle={{
            backgroundColor: '#fff',
            color: '#000',
            fontSize: '14px',
            borderRadius: '4px',
            padding: '8px',
            margin: '15px 15px 0 15px',
          }}
        >
          <div className={styles.cookieConsentMessage}>
            <span>{`${t(
              'We use cookies to improve your experience and for marketing. Read our',
            )} `}</span>
            <Link href="/cookie-policy">{t('Cookie Policy')}</Link>
          </div>
        </CookieConsent>
      )}
    </>
  );
};

export default CookieConsentContent;
